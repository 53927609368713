<template>
  <v-hover
    v-slot:default="{ hover }"
    open-delay="200"
  >
    <v-card
      :elevation="hover ? 16 : 2"
    >
      <v-card 
        flat 
        class=""
        @click="$emit('select', vacancy)"
      >
        <v-overlay v-if="id" absolute :value="selected(vacancy.id)">
          <v-icon class="primary--text" x-large>mdi-check</v-icon>
        </v-overlay>
        <v-card-text> 
          <v-list-item class="d-flex flex-row justify-space-between align-center px-0">
            <v-list-item-avatar
              color="grey"
              size="50"
            >
              <img
                :src=" vacancy.entity.logo
                  ? `${apiUrl}/images/entity/${vacancy.entity.logo}`
                  : `/avatar-whire.png`"
                alt="John"
              />
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item  class="d-flex flex-row justify-space-between align-center px-0 my-1">
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-medium pb-2">{{ vacancy.title }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-icon
                  left
                  small
                >place</v-icon>
                <span
                style="color:#302F2F"
                  v-for="(item, index) in vacancy.place"
                  :key="index"
                >{{ item }},&nbsp;</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <div
          
            style="height: 110px;"
            v-html="vacancy.description ? vacancy.description.substring(0, 100) + '...' : ''"
          ></div>
        </v-card-text>
      </v-card>
      <v-divider class="mx-4" color="#000"></v-divider>
      <v-card-actions>
        <v-btn
          text
          color="secondary"
        >
          <div>{{ vacancy.type }}</div>
        </v-btn>

        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="!vacancy.private"
          icon
          class="mr-3"
          @click="openShareDialog()"
        >
          <v-icon small color="black">mdi-share-variant</v-icon>
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from '@/api'
import { statusVacancyMixins } from '@/mixins/status'
export default {
  name: 'VacancyCard',
  mixins: [
    statusVacancyMixins
  ],
  props: {
    done: Boolean,
    id: String,
    vacancy: Object,
  },
  data: () => ({
    apiUrl: API_URL,
  }),
  methods: {
    selected (id) {
      return this.id == id
    }
  }
}
</script>