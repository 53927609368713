<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
    class="scroll"
  >
    <v-card class="pb-8 scroll px-5">
      <v-toolbar flat>
        <v-row justify="center">
          <v-alert
            v-model="alert"
            color="primary_text" 
            dense
            type="info"
            class="text-center black--text caption mt-0"
            dismissible
          >{{$t('sendvacancyalert')}}</v-alert>
        </v-row>

        <template v-slot:extension>
          <v-tabs v-model="tabs" right color="primary" @change="changeTab()">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#select">
              {{$t('select')}}
            </v-tab>

            <v-tab href="#new">
             {{$t('newvacancy')}}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item value="select">
          <v-card flat>
            <!-- <pre>
              {{currentUser.entity.name}}
            </pre> -->
            <v-card-text>
              <v-row>
                <template v-for="v in vacancies">
                  <v-col
                    cols="12"
                    md="3"
                    :key="v.id"
                  >
                    <vacancy-card :id="vacancy.id" :vacancy="v" @select="selectVacancy" />
                    <!-- <v-card
                      elevation="2"
                      width="300"
                      height="100"
                      tile
                      :color="selected(vacancy.id) ? 'primary' : '#47A6E5'"
                      dark
                      @click="selectVacancy(vacancy)"
                    >
                      <div class="text-center justify-center align-center">{{ vacancy.title }}</div>
                    </v-card> -->
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="new">
          <v-card flat class="">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="vacancy.client"
                    :items="clients"
                    item-text="name"
                    item-value="id"
                    :label="language.clienteName"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="vacancy.type"
                    :items="language.tvacancy"
                    item-text="name"
                    item-value="_id"
                    :label="language.typeVacancy"
                    dense
                    outlined
                    hide-details
                    :rules="requiredRules"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="vacancy.category"
                    :items="language.categoryOptions"
                    item-text="name"
                    item-value="_id"
                    :label="language.category"
                    dense
                    outlined
                    hide-details
                    :rules="requiredRules"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="vacancy.title"
                    :label="language.jobTitle"
                    dense
                    outlined
                    hide-details
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-combobox
                    v-model="vacancy.place"
                    :items="getCountiesMZ"
                    :label="language.provinceState"
                    multiple
                    small-chips
                    outlined
                    dense
                    hide-details
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="vacancy.finalDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="vacancy.finalDate"
                        :label="language.endDate"
                        prepend-inner-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vacancy.finalDate"
                      no-title
                      scrollable
                      :min="dateFormat()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endMenu = false"
                      >Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.save(vacancy.finalDate)"
                      >OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12">
                  <tiptap-vuetify
                    v-model="vacancy.description"
                    :extensions="extensions"
                    :placeholder="language.enterJobDesc"
                    outlined
                    required
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-row class="d-flex flex-row justify-end align-center mt-2 px-6">
        <v-btn
          text
          class="mx-2"
          @click="$emit('close')"
          color="primary"
        >{{$t('cancel')}}</v-btn>
        <v-btn
          v-if="tabs === 'select'"
          :disabled="isLoading"
          color="primary"
          @click="tabs = 'new'"
        >{{$t('next')}}</v-btn>
        <v-btn
          v-else
          :disabled="isLoading"
          color="primary"
          @click="newVacancy ? createVacancy() : sendVacancy()"
        >{{$t('send')}}</v-btn>
      </v-row>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
    <select-language-modal :dialog="lanDialog" @close="lanDialog = false" @set="selectedLanguage" />
  </v-dialog>
</template>

<script>
import { CREATE_PRIVATE_VACANCY_MUTATION, SEND_VACANCY_MUTATION } from './../graphql/Mutation'
import { GET_CLIENTS_ENTITY_QUERY } from './../graphql/Query'
import {ADD_CANDIDATE_NOTIFY} from './../graphql/graphqlNotifyCandidate/Mutation'
import { GET_VACANCIES_ENTITY_QUERY } from './../../vacancy/graphql/Query.resolver'
import { ADD_DIALOG } from '@/mixins/dialog'
import { formatError } from '@/utils'
import { mapGetters } from 'vuex'
import moment from 'moment'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import SelectLanguageModal from '../components/SelectLanguageModal.vue'
import VacancyCard from './VacancyCard.vue'
import Vacancy from '@/models/Vacancy'
import { statusVacancyMixins } from "@/mixins/status";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";
export default {
  name: 'SelectVacancyDialog',
  props: {
    candidates: Array,
    dialog: Boolean
  },
  mixins: [
    ADD_DIALOG, statusVacancyMixins
  ],
  components: { 
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    SelectLanguageModal, 
    TiptapVuetify, 
    VacancyCard
  },
  data: () => ({
    alert: true,
    clients: [],
    endMenu: false,
    lanDialog: false,
    language: {},
    locale: "",
    newVacancy: false,
    tabs: null,
    vacancies: [],
    vacancy: new Vacancy(),
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  apollo: {
    vacancies: {
      query: GET_VACANCIES_ENTITY_QUERY,
      fetchPolicy: 'no-cache'
    },
    clients: {
      query: GET_CLIENTS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    }
  },
  computed: {
    ...mapGetters({
      getCountiesMZ: 'library/countiesMZ',
      getPublishingLang: 'library/publishingLang',
    }),
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    // filteredItems () {
    //   return this.vacancies.filter(v => {
    //     return this.status(v).color === 'red'
    //   })
    // },
    requiredRules () {
      return [v => !!v || "Campo obrigatório"]
    },
  },
  methods: {
    changeTab () {
      if (this.tabs === 'new') {
        this.vacancy = new Vacancy()
        this.newVacancy = true
        this.lanDialog = true
      } else {
        this.newVacancy = false
        this.lanDialog = false
      }
    },
    async createVacancy () {
      this.isLoading = true
      try {
        this.vacancy.locale = this.locale
        await this.$apollo.mutate({
          mutation: CREATE_PRIVATE_VACANCY_MUTATION,
          variables: {
            vacancyInput: { ...this.vacancy, candidates: this.selectedCandidatesFormated() }
          }
        })
        this.success = this.$t('vacancysucsess')
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    dateFormat () {
      return moment(new Date).format('YYYY-MM-DD');
    },
    selectVacancy (vacancy) {
      this.vacancy = vacancy
      this.selectedLanguage(vacancy.locale)
    },
    selected (id) {
      return this.vacancy && this.vacancy.id == id
        ? true
        : false
    },
    selectedLanguage (locale) {
      this.language = this.getPublishingLang(locale)
      this.locale = locale
      this.lanDialog = false;
    },
    async sendVacancy () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: SEND_VACANCY_MUTATION,
          variables: { 
            candidates: this.selectedCandidatesFormated(), 
            vacancyId: this.vacancy.id, 
            specialDate: this.vacancy.finalDate
          }
        })

        this.addCandidateNotify(this.selectedCandidatesFormated(),this.vacancy.id)

        this.success = this.$t('vacancysucsess')
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    selectedCandidatesFormated () {
      return this.candidates.map(c => {
        delete c.age
        delete c.certificates
        delete c.entities
        delete c.fullName
        delete c.notes
        return c
      })
    },
    statusL (vacancy) {
      const dateStart = new Date(vacancy.publicationDate);
      const finalDate = new Date(vacancy.finalDate);
      
      const currentDate = new Date();
      const differenceInTimeS = dateStart.getTime() - currentDate.getTime();
      const differenceInTime = finalDate.getTime() - currentDate.getTime();
      const differenceInDaysS = differenceInTimeS / (1000 * 3600 * 24);
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDaysS > 0) {
        if (vacancy.status) {
          return { text: "Em Espera", color: "warning" };
        } else {
          return { text: "Pendente", color: "blue" };
        }
      }
      if (differenceInDays <= 0) {
        return { text: "Expirado", color: "red" }
      } else if (differenceInDays > 0) {
        if (vacancy.status) {
          return { text: "Publicado", color: "success" };
        } else {
          return { text: "Pendente", color: "blue" };
        }
      }
    },
    async addCandidateNotify(candidates,idType){
      for (let key = 0; key < candidates.length; key++) {
        await this.$apollo.mutate({
          mutation: ADD_CANDIDATE_NOTIFY,
          variables: {
            candidateId:candidates[key].id,
            idType:idType,
            type:"privateVacancy",
            entityName:this.currentUser.entity.name,
            date: moment().format("L"),
            description:'Foi adicionada uma vaga privada para ti'
          }
        })
      }
    }
  }
}
</script>