<template>
  <div class="fill-heigt pt-0" fluid ref="scrollList">
    <v-row>
      <v-col cols="12" md="4">
        <div class="text-left black--text" style="margin-top: 3%">
          {{ $t("create_database") }}
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <!-- <v-btn
          icon
          color="primary"
          @click="infoDialog = true"
        >
          <v-icon>info</v-icon>
        </v-btn> -->
          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="mx-2">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div v-html="$t('tooltip_candidate_filter')"></div>
            </v-tooltip>
          </template>
          <candidate-filter
            :database="true"
            @apply="applyCandidateFilter"
            @clean="applyCandidateFilter"
          />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right px-0 mx-6" style="width: 93.8%">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchCandidate')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="12" md="7">
        <v-row class="d-flex flex-row align-center ml-0">
          <!-- <v-checkbox
            v-model="selectAll"
            color="primary"
            @change="checkSelectAll"
          >
            <template v-slot:label class="pt-50">
              <div>{{ $t("select") }}</div>
            </template>
          </v-checkbox> -->

          <div style="width: 35%">
            <v-autocomplete
              v-model="filter.favorites"
              :items="lists"
              item-text="name"
              item-value="candidates"
              dense
              clearable
              outlined
              :label="$t('favorites')"
              class="mb-2"
              hide-details
              @change="applyFilter()"
            ></v-autocomplete>
          </div>
          <v-checkbox
            v-model="filter.belongsToMany"
            color="primary"
            class="mx-2"
            :value="currentUser.entity.id"
            @change="applyFilter()"
            :label="$t('MyCandidates')"
          >
            <!-- <template v-slot:label class="pt-50">
              <div>{{ $t("MyCandidates") }}</div>
            </template> -->
          </v-checkbox>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <v-btn color="primary" @click="addDialog = true">
          <v-icon left>mdi-account-multiple-plus</v-icon
          >{{ $t("create_candidate") }}
        </v-btn>

        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="accessLevel(currentUser, 'database_send_vacancy')"
                :disabled="!isSelected"
                text
                @click="openSelectVacancyDialog()"
                style="margin-left: 2%"
                color="primary"
              >
                <v-icon left color="primary">mdi-send</v-icon
                >{{ $t("sendvacancy") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_sendVacancy')"></div>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          v-model="selectedCandidates"
          :headers="headers"
          :items="filteredItems"
          show-select
          show-expand
          expand-icon="mdi-eye"
          color="primary"
          class="elevation-1"
          dense
          single-expand
          :no-data-text="$t('tooltip_db')"
          :options.sync="options"
          :server-items-length="candidates.totalDocs"
          :loading="loading"
        >
          <template v-slot:item.photo="{ item }">
            <v-avatar size="40px" style="">
              <img
                :src="
                  item.photo
                    ? `${apiUrl}/images/candidate/${item.photo}`
                    : `/avatar.png`
                "
                alt="perfil"
              />
            </v-avatar>
          </template>
          <template v-slot:item.name="{ item }">
            <span class="text-caption">{{
              item.name + " " + item.lastName
            }}</span>
          </template>
          <template v-slot:item.experience="{ item }"
            ><span class="text-caption"
              >{{ days(item.professionalExperience) }} {{ $t("year")
              }}{{ days(item.professionalExperience) > 1 ? "s" : "" }}</span
            ></template
          >

          <template v-slot:item.email="{ item }">
            <template>
              <v-icon v-on="on" color="primary" @click="openDialogEmail(item)"
                >mdi-email
              </v-icon>
            </template>
          </template>
          <template v-slot:item.notes="{ item }">
            <v-icon v-on="on" color="primary" @click="openDialogNote(item)"
              >mdi-comment-edit</v-icon
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row class="mb-1" style="">
                <!-- <pre>{{ item }}</pre> -->
                <!-- <pre>{{ currentUser.entity.id }}</pre> -->
                <!-- <v-row class="mx-0 mt-5 mb-3">
                            <v-spacer></v-spacer>
                            <div>
                              <v-chip
                                link
                                label
                                outlined
                                color="primary"
                                class="mx-2"
                                @click="openDialog(item)"
                                >{{ $t("Editprofile") }}
                              </v-chip>
                           

                              <template>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      color="primary"
                                      dark
                                      @click="openDialogList(item)"
                                    >
                                      <v-icon small color="#fff" left>mdi-plus</v-icon>
                                      {{ $t("AddtoFavorites") }}
                                    </v-btn>
                                  </template>
                                  <div v-html="$t('tooltip_favlist_candidate')"></div>
                                </v-tooltip>
                              </template>
                            </div>
                          </v-row> -->
                <profile-details
                  @fetchListFav="fetchListFav()"
                  :flat="true"
                  :candidate_="item"
                  :filter="filter"
                  :vacancies="vacancies"
                  :vacancy_Id="{ id: 0 }"
                />
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div>
          <v-progress-linear
            :active="loading"
            :indeterminate="true"
          ></v-progress-linear>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-0" v-if="0">
      <template v-for="i in 12">
        <v-col v-if="loadingData" cols="12" md="3" xl="3" :key="i">
          <skeleton-loading />
        </v-col>
      </template>

      <template v-if="!loadingData">
        <v-col
          v-for="candidate in filteredItems"
          cols="12"
          md="3"
          xl="3"
          :key="candidate.id"
        >
          <!-- {{ filter.page }} -->
          <candidate-card
            :candidate="candidate"
            :filter="filter"
            :from="'candidates'"
            :selected="selectedCandidates"
            :selectAll="selectAll"
            :scrollPosition="`${scrollPosition}`"
            @checked="checkedCandidate"
            :match="'0,00'"
          />
        </v-col>
      </template>
    </v-row>

    <v-row justify="center" v-if="filteredItems.length < 1 && false">
      <div class="d-flex justify-center">
        <v-col cols="12">
          <div class="grey--text" v-html="$t('tooltip_db')"></div>
        </v-col>
      </div>
    </v-row>

    <add-candidate-dialog :dialog="addDialog" @close="addDialog = false" />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <select-vacancy-dialog
      :candidates="selectedCandidates"
      :dialog="selectVacancyDialog"
      @close="selectVacancyDialog = false"
    />
    <!-- <pre>{{ candidate }}</pre> -->
    <edit-profile
      v-if="editCandidateDialog"
      :dialog="editCandidateDialog"
      :CurrentCandidate="candidate"
      @close="openDialog()"
    />
    <candidate-list-dialog
      v-if="dialogList"
      :candidateId="candidate.id"
      :dialog="dialogList"
      @close="dialogList = false"
    />

    <send-email-to-candidate
      :dialog="dialogEmail"
      :candidate="candidate"
      @close="closeAddDialog()"
      @done="sendEmailDone()"
    ></send-email-to-candidate>
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <note-candidate-dialog
      v-if="dialogNote"
      :candidateId="candidate"
      :dialog="dialogNote"
      @close="dialogNote = false"
    />
    <div id="sensor"></div>
  </div>
</template>

<script>
import {
  GET_CANDIDATES_ENTITY_QUERY,
  GET_CANDIDATE_LISTS_QUERY,
  GET_CANDIDATES_ENTITY_SEARCH,
} from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import scrollMonitor from "scrollmonitor";
import CandidateCard from "../../../components/CandidateCard.vue";
import CandidateFilter from "../../../components/CandidateFilter.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
// import Pagination from '../../../components/Pagination.vue'
import AddCandidateDialog from "../components/AddCandidateDialog.vue";
import SelectVacancyDialog from "./../components/SelectVacancyDialog";
import SkeletonLoading from "../../../components/SkeletonLoading.vue";
// import InfiniteScroll from '../../../components/InfiniteScroll.vue'
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import accessLevelMixins from "@/mixins/access-level";
import { UPDATED_CANDIDATE } from "../graphql/subscription";
import yearsMixins from "@/mixins/years";
import { API_URL } from "@/api";
import ProfileDetails from "./Profile.vue";
import EditProfile from "./../components/EditProfile.vue";
import CandidateListDialog from "../components/CandidateListDialog.vue";
import SendEmailToCandidate from "./../components/SendEmailToCandidate.vue";
import SuccessDialog from "@/modules/entity/components/SuccessDialog.vue";
import NoteCandidateDialog from "./../components/NoteCandidateDialog.vue";
import { GET_PUBLISHED_VACANCIES_QUERY } from "../../vacancy/graphql/Query.resolver";
export default {
  name: "Candidate",
  components: {
    SendEmailToCandidate,
    NoteCandidateDialog,
    SuccessDialog,
    CandidateCard,
    CandidateListDialog,
    CandidateFilter,
    InfoViewDialog,
    EditProfile,
    // Pagination,
    AddCandidateDialog,
    SelectVacancyDialog,
    SkeletonLoading,
    ProfileDetails,
    // InfiniteScroll
  },
  mixins: [replaceSpecialCharsMixins, accessLevelMixins, yearsMixins],
  data: () => ({
    addDialog: false,
    apiUrl: API_URL,
    appliedFilter: true,
    dialogList: false,
    dialogEmail: false,
    dialogNote: false,
    candidates: [],
    candidate: {},
    success: "",
    showSuccess: false,
    blockeds: [],
    filter: {
      areasInterest: undefined,
      otherSkills: undefined,
      province: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 10,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
      sector: undefined,
    },
    filterSlot: undefined,
    infoDialog: false,
    // infoView: {
    //   icon: 'mdi-badge-account-horizontal-outline',
    //   title: 'Base de dados',
    //   content: 'Encontre num só ambiente todos candidatos, filtre de acordo com os requisitos da vaga, visualize os perfis e envie vagas privadas.'
    // },
    loadingData: true,
    list: undefined,
    lists: [],
    totalCandidates: null,
    // totalPages: 1,
    selectAll: false,
    selectedCandidates: [],
    selectVacancyDialog: false,
    textSearch: undefined,
    scrollPosition: 0,
    editCandidateDialog: false,
    loading: false,
    options: {},
  }),
  apollo: {
    vacancies: {
      query: GET_PUBLISHED_VACANCIES_QUERY,
      // fetchPolicy: "no-cache",
    },
    candidates: {
      query: GET_CANDIDATES_ENTITY_QUERY,
      // fetchPolicy: "no-cache",
      variables() {
        return { filterCandidate: this.filter };
      },
      result({ loading }) {
        if (!loading) {
          this.loadingData = false;
        }
      },
    },
    lists: {
      query: GET_CANDIDATE_LISTS_QUERY,
      // fetchPolicy: "no-cache",
    },
    $subscribe: {
      message: {
        query: UPDATED_CANDIDATE,
        result({ data }) {
          if (data.updateCandidate) {
            const index = this.candidates.candidates.findIndex(
              (i) => i.id === data.updateCandidate.id
            );
            if (index === -1) {
              // console.log('subs',index,data);
              this.candidates.candidates.unshift(data.updateCandidate);
            }
          }
        },
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    textSearch(val) {
      console.log(val);
      if (val) {
        this.querySelections(val);
      } else {
        this.applyFilter();
      }
    },
  },
  created() {
    if (
      this.$route.query.filter &&
      this.verifyFilter(this.$route.query.filter)
    ) {
      let urlFilter = this.$route.query.filter;
      console.log("urlFilter: ", urlFilter);
      urlFilter.limit = 10;
      this.filter = urlFilter;
      this.applyFilter();
    }

    // eslint-disable-next-line no-undef
    Fire.$on("addedCandidates", (candidates) => {
      this.candidates.candidates = [
        ...candidates,
        ...this.candidates.candidates,
      ];
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getCandidates: "candidate/getCandidates",
    }),
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photo",
          width: "0.1%",
        },

        {
          text: this.$t("form_candidate_name"),
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
        {
          text: this.$t("form_candidate_job"),
          align: "start",
          sortable: true,
          value: "profession",
          width: "15%",
        },
        {
          text: this.$t("years_of_experience"),
          align: "start",
          sortable: true,
          value: "experience",
          width: "20%",
        },
        // {
        //   text: this.$t("match"),
        //   sortable: true,
        //   value: "punctuation",
        //   width: "17%",
        // },
        // {
        //   text: this.$t("submission_date"),
        //   align: "center",
        //   sortable: false,
        //   value: "specialDate",
        //   width: "15%",
        // },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
          width: "0%",
        },
        {
          text: this.$t("Notepad"),
          align: "center",
          sortable: false,
          value: "notes",
          width: "20%",
        },
        {
          text: this.$t("form_candidate_profile"),
          align: "center",
          sortable: false,
          // value: "profile",
          value: "data-table-expand",
        },
      ];
    },
    filteredItems() {
      let resultItems = this.candidates.candidates
        ? this.candidates.candidates
        : [];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter(
          (t) =>
            this.replaceSpecialChars(t.name + " " + t.lastName).includes(
              text
            ) ||
            (t.profession &&
              this.replaceSpecialChars(t.profession).includes(text))
        );
      }
      return resultItems;
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    infoView() {
      return {
        icon: "mdi-badge-account-horizontal-outline",
        title: this.$t("create_database"),
        content: this.$t("info_database"),
      };
    },
  },

  beforeDestroy() {
    // Remove o listener quando o componente for destruído para evitar vazamentos de memória
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions({
      setCandidatesState: "candidate/setCandidates",
      setCandidatesScrolled: "candidate/setCandidatesScrolled",
    }),
    async movePage() {
      const { page, itemsPerPage } = this.options;
      console.log({ page, itemsPerPage });
      this.filter.page = page;
      this.filter.limit =
        itemsPerPage === -1 ? this.candidates.totalDocs : itemsPerPage;
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_CANDIDATES_ENTITY_QUERY,
              variables: {
                filterCandidate: this.filter,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },
    getDataFromApi() {
      this.loading = true;
      this.movePage().then((response) => {
        console.log(response.data);
        this.candidates = response.data.candidates;
        this.loading = false;
      });
    },
    async searchCandidate(name) {
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_CANDIDATES_ENTITY_SEARCH,
              variables: {
                name: name,
                limit: 10,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },
    querySelections(name) {
      this.loading = true;
      this.searchCandidate(name).then((response) => {
        console.log(response.data);
        this.candidates = response.data.candidates;
        this.loading = false;
      });
    },
    openDialogNote(candidate) {
      this.candidate = candidate;
      this.dialogNote = true;
    },
    openDialogEmail(candidate) {
      this.candidate = candidate;
      this.dialogEmail = true;
    },
    closeAddDialog() {
      this.show = false;
      this.dialogEmail = false;
    },
    sendEmailDone() {
      this.closeAddDialog();
      this.success = this.$t("email_sent_successfully");
      this.showSuccess = true;
    },
    openDialog(candidate) {
      this.candidate = candidate;
      this.editCandidateDialog = !this.editCandidateDialog;
    },
    openDialogList(candidate) {
      this.candidate = candidate;
      this.dialogList = !this.dialogList;
    },
    applyCandidateFilter(filter) {
      this.filter = {
        ...this.filter,
        areasInterest: filter.areasInterest,
        otherSkills: filter.otherSkills,
        province: filter.province ? `${filter.province}` : filter.province,
        formation: filter.formation,
        languages: filter.languages,
        maxYear: filter.maxYear,
        minYear: filter.minYear,
        maxSalary: filter.maxSalary,
        minSalary: filter.minSalary,
        professionalExperience: filter.professionalExperience,
        sector: filter.sector,
        limit: 1000,
      };

      this.refetchCandidates();
    },

    async refetchCandidates() {
      this.loadingData = true;
      this.loading = true;
      this.$apollo.queries.candidates.skip = false;
      this.filter.page = 1;
      await this.$apollo.queries.candidates.refetch();
      this.loading = false;
    },

    async applyFilter() {
      this.loadingData = true;
      this.filter.page = 1;
      this.filter.limit =
        this.options.itemsPerPage === -1
          ? this.candidates.totalDocs
          : this.options.itemsPerPage;
      await this.$apollo.queries.candidates.refetch();
    },
    async fetchListFav() {
      await this.$apollo.queries.lists.refetch();
    },
    changePage(page) {
      this.filter.page = page;
      this.applyFilter();
    },
    checkedCandidate(id) {
      console.log(id);
      const index = this.selectedCandidates.findIndex((c) => c.id == id);
      if (index > -1) {
        this.selectedCandidates.splice(index, 1);
      } else {
        const candidate = this.filteredItems.find((c) => c.id == id);
        this.selectedCandidates.push(Object.assign({}, { ...candidate }));
      }
    },
    checkSelectAll() {
      if (this.selectAll) {
        for (let index = 0; index < this.filteredItems.length; index++) {
          if (!this.filteredItems[index].blocked) {
            this.selectedCandidates.push(
              Object.assign({}, this.filteredItems[index])
            );
          }
        }
      } else {
        this.selectedCandidates = [];
      }
    },
    fetchMore() {
      this.filter.page += 1;
      console.log("this.filter.page: ", this.filter.page);
      this.$apollo.queries.candidates.fetchMore({
        variables: {
          filter: this.filter,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.candidates.candidates = [
            ...this.candidates.candidates,
            ...fetchMoreResult.candidates.candidates,
          ];
          return {
            candidates: {
              candidates: [...this.candidates.candidates],
              totalDocs: fetchMoreResult.candidates.totalDocs,
              totalPages: fetchMoreResult.candidates.totalPages,
              blockeds: fetchMoreResult.candidates.blockeds
                ? fetchMoreResult.candidates.blockeds
                : null,
            },
          };
        },
      });
    },
    openSelectVacancyDialog() {
      if (this.selectedCandidates.length > 0) {
        this.selectVacancyDialog = true;
      }
    },
    handleScroll() {
      // Pega a posição do scroll na vertical
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.scrollPosition = scrollTop;

      // Faça algo com a posição do scroll, por exemplo, exiba no console
      console.log("Posição do scroll:", scrollTop);
    },
    verifyFilter(filter) {
      let count = 0;
      for (const key in filter) {
        switch (key) {
          case "formation":
            if (filter[key][0].course) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "languages":
            if (filter[key][0].name) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "limit":
            break;
          case "page":
            break;

          default:
            if (filter[key]) {
              count++;
            }
            break;
        }
      }
      return count > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    const el = document.getElementById("sensor");
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (this.candidates && this.filter.page <= this.candidates.totalPages) {
        // this.fetchMore();
      }
    });
  },
};
</script>
